<template>
  <div class="wrapper">
    <div :class="'sidebar' + ($store.state.menuMobile ? ' sidebar-forced' : '')">
      <ul>
        <router-link
          :to="{ name: 'Home' }"
          active-class="current-page"
          exact
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> terminal </i
            ><span>Sistemas</span>
          </li>
        </router-link>

     <ul id="menuSistemas">
        <router-link
          :to="{ name: 'Modulos' }"
          active-class="current-page"
          :exact="true"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> extension </i
            ><span>Módulos</span>
          </li>
        </router-link>

        <router-link
          :to="{ name: 'Rotas' }"
          active-class="current-page"
          :exact="true"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> link </i><span>Rotas</span>
          </li>
        </router-link>

        <router-link
          :to="{ name: 'Dicionarios' }"
          active-class="current-page"
          :exact="true"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> compare_arrows </i
            ><span>Dicionários</span>
          </li>
        </router-link>
     </ul>
        <router-link
          :to="{ name: 'Clientes' }"
          active-class="current-page"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> apartment </i
            ><span>Clientes</span>
          </li>
        </router-link>

        <router-link
          :to="{ name: 'Usuarios' }"
          active-class="current-page"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="material-icons-outlined"> person </i><span>Usuários</span>
          </li>
        </router-link>

        <router-link
          :to="{ name: 'Integracoes' }"
          active-class="current-page"
          custom
          v-slot="{ navigate }"
        >
          <li @click="navigate">
            <i class="menu-icon icon-custom-system"></i><span>Integrações</span>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
}
</script>

<style lang="stylus" scoped>

@import '../../style/colors.styl';
@import '../../style/dimens.styl';
//@import '../../style/fonts.styl';
@import '../../style/mixins.styl';

// ----------------------------------
// Dimensions
// ----------------------------------
sidebar-width = 64px // 20%

// ----------------------------------
// Sidebar
// ----------------------------------
.sidebar
    background-color #262626
    color content
    // display flex
    // justify-content space-between
    // align-items center
    position fixed
    width sidebar-width
    top topbar-height
    min-height: 100%
    z-index 10
    filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2));
    transition 0.2s ease-in-out

    @media (max-width: md)
        top topbar-height * topbar-sm-resize
        display none

    &:hover
        width 192px

        ul li
            justify-content left
            padding-left 20px // (64 - 24) / 2
            transition 0s

            span
                opacity 1
                width auto
                height auto
                visibility visible
                left 20px
                top 18px

@media (max-width: md)
    .sidebar-forced
        display flex !important
        width 192px

        ul li
            justify-content left
            padding-left 20px // (64 - 24) / 2
            transition 0s

            span
                opacity 1
                width auto
                height auto
                visibility visible
                left 20px
                top 18px

.wrapper
    width sidebar-width
    // min-width sidebar-width
    flex-shrink: 0
    min-height: 100%

    @media (max-width: md)
      width 0

// ----------------------------------
// Items
// ----------------------------------
ul
    list-style none
    padding 0
    margin 0

    li
        position relative
        width 100%
        height 64px
        display flex
        align-items center
        justify-content center
        transition 0.2s ease-in-out
        cursor pointer
        nowrap()
        no-select()

        &:hover
            background-color #262626
            color secondary

        i
            position absolute
            left 20px
            font-size 24px
            height 24px

        span
            opacity 0
            width 0
            height 0
            visibility hidden
            left 0
            top 18px
            transition 0.2s ease-in-out
            position absolute
            margin 3px 0 0 36px
            font-weight bold

 /*  ul#menuSistemas
    display none */
</style>
